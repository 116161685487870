import React, { useState } from 'react';
import {AiOutlineSend} from 'react-icons/ai'
import validate from './validateInfo';
import useForm from './useForm';
import './Form.css';
import { useRef } from 'react';
import SendMail from './SendMail';
const FormSignup = ({ submitForm }) => {
  const { handleChange, handleSubmit, values, errors} = useForm(
    submitForm,
    validate
  );
  const nameref=useRef("")
  const mailref=useRef("")
  const messageref=useRef("")
  return (
    <div className='form-content-right'>
      <form onSubmit={handleSubmit} className='form' noValidate>
        <div className='form-inputs'>
          <label className='form-label'>Name</label>
          <input
            ref={nameref}
            className='form-input'
            type='text'
            name='Name'
            placeholder='Enter your Name'
            value={values.Name}
            onChange={handleChange}
          />
          {errors.Name && <p>{errors.Name}</p>}
        </div>
        <div className='form-inputs'>
          <label className='form-label'>Phone</label>
          <input
            className='form-input'
            name='Phone'
            placeholder='Enter your Phone Number'
            value={values.Phone}
            onChange={handleChange}
          />
          {errors.Phone && <p>{errors.Phone}</p>}
        </div>
        <div className='form-inputs'>
          <label className='form-label'>Email</label>
          <input
            ref={mailref}
            className='form-input'
            type='email'
            name='Email'
            placeholder='Enter your Email'
            value={values.Email}
            onChange={handleChange}
          />
          {errors.Email && <p>{errors.Email}</p>}
        </div>
        <div className='form-inputs'>
          <label className='form-label'>Your Message</label>
          <input
          ref={messageref}
            className='form-input'
            type='text'
            name='Message'
            placeholder='Type Your Message'
            value={values.Message}
            onChange={handleChange}
          />
          {errors.Message && <p>{errors.Message}</p>}
        </div>
        <button className='form-input-btn' type='submit'>
          <span>Send</span>
          <span ><AiOutlineSend/></span>
        </button>
      </form>
    </div>
  );
};

export default FormSignup;
