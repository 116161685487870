import React from 'react'
import { MdMailOutline, MdOutlinePhoneInTalk } from 'react-icons/md'
import Slider from '../Animate'
import Footer from '../Footer'
import Navbar from '../Navbar'
import Consultant from '../../assets/Slider Images/Consultant.png'
import './MisConsultancy.css'

const MisConsultancy = () => {
  return (
    <div>
      <div>
        <Navbar/>
        <div className='containermis'>
            <div className="write">
                <h1 className='font-bold underline'>Mis Consultancy :</h1>
                <div>From strategy through execution, ARL-Technology professional consulting service helps you get the most ground-breaking business solution.</div>
                <div>We have developed, applied and refined methods for delivering solutions that play a key role in the strategic planning process of a company, to provide statistical analysis regarding customers, market trends, forecasting, and performance management.</div>
            </div>
                <div>
                    <Slider
                    imageSrc={Consultant}
                    title={null}
                    subtitle={null}
                    size={'100%'}
                    />
                </div>
            </div>
        <hr/>
        <div className='resp'>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdOutlinePhoneInTalk/></span>
                <span className='text-indigo-600'> +0044 545 989 626</span>
            </div>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdMailOutline/></span>
                <span className='text-indigo-600 underline'> contact@arltechnology.co.uk</span>
            </div>
        </div>
        <Footer/>
    </div>
    </div>
  )
}

export default MisConsultancy
