import React from 'react'
import { MdMailOutline, MdOutlinePhoneInTalk } from 'react-icons/md'
import { VscStarFull } from 'react-icons/vsc'
import Slider from '../Animate'
import Footer from '../Footer'
import Navbar from '../Navbar'
import Agendas from '../../assets/Slider Images/Agenda.png'
import './Agenda.css'
const Agenda = () => {
  return (
    <div>
        <Navbar/>
        <div className='container8'>
            <div>
                <h1 className='font-bold underline'>Agenda</h1>
                <Slider
                    imageSrc={Agendas}
                    title={null}
                    subtitle={null}
                    items={false}
                    flipped={true}
                    size={'10%'}
                />
            </div>
        
        
          <div className='word'>
                <div className="content8">
                  <div className='text-xl'><span className='font-bold'>Agenda</span>  is a professional mobile application supporting service providers by :</div>
                  <div>
                        <div className='flex'>
                            <span className='pr-2'><VscStarFull/></span>
                            <span>Electronic Healthcare Records System.</span>
                        </div>
                        <div className='flex'>
                            <span className='pr-2'><VscStarFull/></span>
                            <span>Appointment Reservation System.</span>
                        </div>
                        <div className='flex'>
                            <span className='pr-2'><VscStarFull/></span>
                            <span>Online Payment Facility.</span>
                        </div>
                        <div className='flex'>
                            <span className='pr-2'><VscStarFull/></span>
                            <span>Smart Queuing System</span>
                        </div>
                        <div className='flex'>
                            <span className='pr-2'><VscStarFull/></span>
                            <span>Professional Video Calling Service.</span>
                        </div>
                  </div>
                </div>
                <div>
                    <div className="content8">
                      <div className='text-xl'><span className='font-bold'>Agenda</span> helps these providers to achieve a higher level of customer satisfaction, through:</div>
                      <div>
                            <div className='flex'>
                                <span className='pr-2'><VscStarFull/></span>
                                <span>Organizing customers' records safely.</span>
                            </div>
                            <div className='flex'>
                                <span className='pr-2'><VscStarFull/></span>
                                <span>Enabling appointment reservation 24/7.</span>
                            </div>
                            <div className='flex'>
                                <span className='pr-2'><VscStarFull/></span>
                                <span>Facilitating the payment process.</span>
                            </div>
                            <div className='flex'>
                                <span className='pr-2'><VscStarFull/></span>
                                <span>Minimize waiting time.</span>
                            </div>
                            <div className='flex'>
                                <span className='pr-2'><VscStarFull/></span>
                                <span>Offering a reliable communication channel with customers.</span>
                            </div>
                      </div>
                    </div>
                </div>
          </div>
        </div>
        <hr/>
        <div className='contact6'>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdOutlinePhoneInTalk/></span>
                <span className='text-indigo-600'> +0044 545 989 626</span>
            </div>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdMailOutline/></span>
                <span className='text-indigo-600 underline'> contact@arltechnology.co.uk</span>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Agenda
