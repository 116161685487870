import React from 'react'
import Call from '../../assets/Slider Images/Call.png'
import '../../Animate.css'
import Slider from '../Animate'
import Navbar from '../Navbar'
import { MdMailOutline, MdOutlinePhoneInTalk } from 'react-icons/md'
import Footer from '../Footer'
import './CallReporting.css'
const CallReports = () => {
  return (
    <div>
        <Navbar/>
        <div className='container4'>
            <div className="content4">
                <h1 className='pb-8 font-bold text-lg underline'>Call Reporting System (ARL-CRS)</h1>
                <div className='p-3'>
                    <span className='font-bold'>ARL-CRS</span> is a software solution which enables decision makers to right allocate resources and maximize benefits out of Sales Force efforts.
                </div>
                <div className='p-3'>
                    <span className='font-bold'>ARL-CRS</span> helps its users to improve the efficacy and adjust performance to be effectively reflected on achievement.
                </div>
                <div className='p-3'>
                    <span className='font-bold'>ARL-CRS</span> enables its users through some simple clicks to compare performance with pre-set standards to avoid subjectivity in any decisions.
                </div>
            </div>
                <div>
                    <Slider
                    imageSrc={Call}
                    title={null}
                    subtitle={null}
                    size={'100%'}
                    />
                </div>
            </div>
            <hr/>
        <div className='contact4'>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdOutlinePhoneInTalk/></span>
                <span className='text-indigo-600'> +0044 545 989 626</span>
            </div>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdMailOutline/></span>
                <span className='text-indigo-600 underline'> contact@arltechnology.co.uk</span>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default CallReports
