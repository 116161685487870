export default function validateInfo(values) {
  let errors = {};

  if (!values.Name.trim()) {
    errors.Name = 'Name required';
  }
  else if (!/^[A-Za-z]+/.test(values.Name.trim())) {
      errors.Name = 'Enter a valid name';
  }

  if (!values.Email) {
    errors.Email = 'Email required';
  } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.Email)) {
    errors.Email = 'Email address is invalid';
  }
  if (!values.Phone) {
    errors.Phone = 'Phone is required';
  } else if ((values.Phone.length < 12) && (!/^[0-9]+/.test(values.Phone.trim()))) {
    errors.Phone = 'Password needs to be numeric and contains 12 characters or more';
  }

  if (!values.Message.trim()) {
    errors.Message = 'message required';
  }
  return errors;
}
