import React from 'react'
import { MdMailOutline, MdOutlinePhoneInTalk } from 'react-icons/md'
import Slider from '../Animate'
import Footer from '../Footer'
import Navbar from '../Navbar'
import SalesForce from '../../assets/Slider Images/SalesForce.png'
import './Sales Force Auto.css'

const SalesForceAuto = () => {
  return (
    <div>
        <Navbar/>
        <div className='container6'>
          <div className="content6">
            <h1 className='font-bold pb-28 underline'>Sales Force Automation (ARL-SFA)</h1>
            <div><span className='font-bold'>ARL-SFA</span> is a software solution which enables decision makers to right allocate resources and maximize benefits out of Sales Force efforts.</div>
            <div><span className='font-bold'>ARL-SFA</span> helps its users to improve the efficacy and adjust performance to be effectively reflected on achievement.</div>
            <div><span className='font-bold'>ARL-SFA</span> enables its users through some simple clicks to compare performance with pre-set standards to avoid subjectivity in any decisions.</div>
            <div><span className='font-bold'>ARL-SFA</span> is simple, flexible, and secure software, by which and with some simple clicks decision makers can build their decisions on an updated, meaningful interpretation of sales figures.</div>
            <div><span className='font-bold'>ARL-SFA</span> is a solution that enables decision makers to make right decisions based on a solid comparative base of knowledge that is capable to compare historical data with the current situation.</div>
            <div><span className='font-bold'>ARL-SFA</span> enables its users to have a closer monitor on their customers and gives them an insight view for sales achievement through accurate, unified and unbiased measures of performance.</div>
            <div><span className='font-bold'>ARL-SFA</span> is an integrated system that combines the Call Reporting System with the Sales Analysis System where data is integrated perfectly.</div>
          </div>
            
          <Slider
            imageSrc={SalesForce}
            title={null}
            subtitle={null}
            items={false}
            flipped={true}
            size={'100%'}
            />
        </div>
        <hr/>
        <div className='contact6'>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdOutlinePhoneInTalk/></span>
                <span className='text-indigo-600'> +0044 545 989 626</span>
            </div>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdMailOutline/></span>
                <span className='text-indigo-600 underline'> contact@arltechnology.co.uk</span>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default SalesForceAuto
