import React from 'react'
import Navbar from '../Navbar'
import Form from './Form/Form'
const Contactus = () => {
  return (
    <div>
        <Navbar/>
        <div className='m-auto pt-28 bg-zinc-200'>
            <Form/>
        </div>
    </div>
  )
}

export default Contactus
