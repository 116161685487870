import React from 'react'
import { MdMailOutline, MdOutlinePhoneInTalk } from 'react-icons/md'
import Slider from '../Animate'
import Footer from '../Footer'
import Navbar from '../Navbar'
import PointOfSales from '../../assets/Slider Images/PointOfSales.png'
import './PointOfSales.css'

const PointOfSale = () => {
  return (
    <div>
        <Navbar/>
        <div className='container5'>
          <div className="content5">
            <h1 className='font-bold pb-20 underline'>ARL Point Of Sales (ARL-POS)</h1>
            <div><span className='font-bold'>ARL-POS</span> is a software that manages sales invoices, purchasing orders and some financial statements.</div>
            <div><span className='font-bold'>ARL-POS</span> is a solution by which our partners can manage inventory and re-ordering process based on up-to-date records.</div>
            <div><span className='font-bold'>ARL-POS</span> enables its users to have a close monitor on customers' and suppliers' collection and payments.</div>
          </div>
            
          <Slider
            imageSrc={PointOfSales}
            title={null}
            subtitle={null}
            items={false}
            flipped={true}
            size={'100%'}
            />
        </div>
        <hr/>
        <div className='contact5'>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdOutlinePhoneInTalk/></span>
                <span className='text-indigo-600'> +0044 545 989 626</span>
            </div>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdMailOutline/></span>
                <span className='text-indigo-600 underline'> contact@arltechnology.co.uk</span>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default PointOfSale
