import React from 'react'
import { MdMailOutline, MdOutlinePhoneInTalk } from 'react-icons/md'
import Slider from './Animate'
import Footer from './Footer'
import Navbar from './Navbar'
import alliance from '../assets/Slider Images/Alliances.png'
const Alliances = () => {
  return (
    <div>
        <Navbar/>
        <div className='container'>
          <div className="write">
            <h1 className='font-bold underline'>Alliances :</h1>
            <div>ARL-Technology delivers intelligence through partnerships, since we know that none of us works as smart as all of us. With a focus on creativity, ARL-Technology Alliance establishes powerful relationships with leading business and technology organizations. We strive to combine our analytical software expertise with our partners' high-level knowledge in order to present a complete solution offering for our joint customers, to make business enjoyable.</div>
            <div>We are willing to host and support any creative idea that could help us to meet our customers growing expectations. Together, ARL-Technology and our Alliance members develop, sell and deliver the most comprehensive intelligence solutions available.</div>
          </div>
            
          <Slider
            imageSrc={alliance}
            title={null}
            subtitle={null}
            items={false}
            flipped={true}
            size={'100%'}
            />
        </div>
        <hr/>
        <div className='resp'>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdOutlinePhoneInTalk/></span>
                <span className='text-indigo-600'> +0044 545 989 626</span>
            </div>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdMailOutline/></span>
                <span className='text-indigo-600 underline'> contact@arltechnology.co.uk</span>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Alliances
