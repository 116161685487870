import React from 'react'
import { MdMailOutline, MdOutlinePhoneInTalk } from 'react-icons/md'
import {FcCustomerSupport} from 'react-icons/fc'
import tech from '../../assets/Slider Images/tech-supp.png'
import Slider from '../Animate'
import Footer from '../Footer'
import Navbar from '../Navbar'
import './Technical Support.css'
const TechnicalSupport = () => {
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
      };
  return (
    <div>
            <div>
        <Navbar/>
        <div className='container10'>
          <div className="content10">
            <h1 className='font-bold underline'>Technical Support</h1>
            <p>We are always committed with the technical support terms of specially signed agreements organizing the long term relationship with our customers whom are considered partners in our success.</p>
            </div> 
            <Slider
                imageSrc={tech}
                title={null}
                subtitle={null}
                items={false}
                flipped={true}
                size={'100%'}
                />
            </div>
            <div className='text-center'>
                To use ARL-Technology Support press :   <button onClick={() => openInNewTab('http://support.ARL-technology.com/')}><span className='text-6xl pt-7'><FcCustomerSupport/></span></button>
            </div>
        </div>

        
          
        <hr/>
        <div className='contact10'>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdOutlinePhoneInTalk/></span>
                <span className='text-indigo-600'> +0044 545 989 626</span>
            </div>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdMailOutline/></span>
                <span className='text-indigo-600 underline'> contact@arltechnology.co.uk</span>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default TechnicalSupport
