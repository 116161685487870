import './App.css';
import { BrowserRouter,Routes,Route} from 'react-router-dom';
import Home from './components/Home/Home';
import Vision from './components/Vision/Vision';
import Mission1 from './components/Mission/Mission.jsx';
import CoreValues from './components/Core Values/CoreValues';
import SalesAnalysis from './components/Sales Analysis/SalesAnalysis';
import CallReports from './components/Call Reporting System/CallReports';
import PointOfSales from './components/Point Of Sales/PointOfSales.jsx';
import SalesForceAuto from './components/Sales Force Auto/SalesForceAuto.jsx';
import MobileDevelo from './components/Mobile Application/MobileDevelo.jsx';
import Agenda from './components/Agenda/Agenda';
import WebDesign from './components/Web Design/WebDesign';
import Contactus from './components/Contact Us/Contactus';
import TechnicalSupport from './components/Technical Supports/TechnicalSupport';
import ScrollTop from './components/ScrollTop';
import MisConsultancy from './components/Mis Consultancy/Mis Consultancy';
import Alliances from './components/Alliances';
function App() {
  return (
      <BrowserRouter>
        <ScrollTop/>
          <Routes>
            <Route path={"/"} element={<Home/>}/>
            <Route path={"/Vision"} element={<Vision/>}/>
            <Route path={"/Mission"} element={<Mission1/>}/>
            <Route path={"/Core"} element={<CoreValues/>}/>
            <Route path={"/salesan"} element={<SalesAnalysis/>}></Route>
            <Route path={"/callrep"} element={<CallReports/>}></Route>
            <Route path={"/Point"} element={<PointOfSales/>}></Route>
            <Route path={"/SalesForce"} element={<SalesForceAuto/>}></Route>
            <Route path={"/Mobile"} element={<MobileDevelo/>}></Route>
            <Route path={"/Agenda"} element={<Agenda/>}></Route>
            <Route path={"/web"} element={<WebDesign/>}></Route>
            <Route path={"/Contact"} element={<Contactus/>}></Route>
            <Route path={"/technicalsupp"} element={<TechnicalSupport/>}></Route>
            <Route path={"/Consultancy"} element={<MisConsultancy/>}></Route>
            <Route path={"/Alliance"} element={<Alliances/>}></Route>
        </Routes>
      </BrowserRouter>
          
  );
}

export default App;
