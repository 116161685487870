import { useState, useEffect } from 'react';
import SendMail from './SendMail';

const useForm = (callback, validate) => {
  const [values, setValues] = useState({
    Name: '',
    Phone: '',
    Email: '',
    Message: ''
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const handleSubmit = e => {
    SendMail(values.Email,values.Name,values.Message)
    e.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  useEffect(
    () => {
      if (Object.keys(errors).length === 0 && isSubmitting) {
        callback();
      }
    },
    [errors]
  );

  return { handleChange, handleSubmit, values, errors};
};

export default useForm;
