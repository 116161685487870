import React from 'react'
import Slider from '../Animate'
import Navbar from '../Navbar'
import '../../Animate.css'
import './Sales.css'
import salesanalysissystem from '../../assets/Slider Images/salesanalysissystem.png'
import { MdMailOutline, MdOutlinePhoneInTalk } from 'react-icons/md'
import Footer from '../Footer'
const SalesAnalysis = () => {
  return (
    <div>
        <Navbar/>
        <div className='container3'>
            <div className="content3">
                <h1 className='pb-8 font-bold text-lg underline'>Sales Analysis System (ARL-SAS)</h1>
                <div className='p-3'>
                    <span className='font-bold'>ARL-SAS</span> is simple, flexible, and secure software, by which and with some simple clicks decision makers can build their decisions on an updated, meaningful interpretation of sales figures.
                </div>
                <div className='p-3'>
                    <span className='font-bold'>ARL-SAS</span> is a solution that enables decision makers to make right decisions based on a solid comparative base of knowledge that is capable to compare historical data with the current situation.
                </div>
                <div className='p-3'>
                    <span className='font-bold'>ARL-SAS</span> enables its users to have a closer monitor on their customers and gives them an insight view for sales achievement through accurate, unified and unbiased measures of performance.
                </div>
            </div>
                <div>
                    <Slider
                    imageSrc={salesanalysissystem}
                    title={null}
                    subtitle={null}
                    items={false}
                    flipped={false}
                    size={'100%'}
                    />
                </div>
            </div>
            <hr/>
        <div className='contact3'>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdOutlinePhoneInTalk/></span>
                <span className='text-indigo-600'> +0044 545 989 626</span>
            </div>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdMailOutline/></span>
                <span className='text-indigo-600 underline'> contact@arltechnology.co.uk</span>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default SalesAnalysis
