import React, {useState} from 'react';
import {animateScroll as scroll, } from 'react-scroll'
import Logo from "../assets/support-and-datareports-analyst-for-ark-technology-62ae3bf6df1da-removebg-preview.png"
import { Link } from 'react-router-dom';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import Navlinks from './navlink';

const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleClick = () => setNav(!nav)

    const handleClose =()=> setNav(!nav)

  return (
    <div className='w-screen h-[80px] z-10 bg-zinc-200 fixed drop-shadow-lg'>
      <div className='px-2 flex justify-between items-center w-full h-full'>
        <div className='itms'>
        <img src={Logo} alt="logo" className="w-52 h-48"/>
          <ul className='mb'>
             <Navlinks/>
          </ul>
        </div>
        <div className='mob' onClick={handleClick}>
            {!nav ? <MenuIcon className='w-5' /> : <XIcon className='w-5' />}
        </div>
      </div>

      <ul className={!nav ? 'hidden' : 'absolute bg-zinc-200 w-full px-8'}>
          <Navlinks/>
      </ul>
    </div>
  );
};

export default Navbar;
