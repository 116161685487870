import React from 'react'
import { MdMailOutline, MdOutlinePhoneInTalk } from 'react-icons/md'
import Slider from '../Animate'
import Footer from '../Footer'
import Navbar from '../Navbar'
import Mobile from '../../assets/Slider Images/mobile-development.png'
import './Mobile Application.css'

const MobileDevelo = () => {
  return (
    <div>
        <Navbar/>
        <div className='container7'>
          <div className="content7">
            <h1 className='font-bold underline'>Mobile Applications</h1>
            <div><span className='font-bold'>ARK-Technology</span> develops customized Mobile Applications that will help business of our partners to grow and expand, we do all the needed development; Android front end, back end, databases and any web services needed, which will eventually lead to excellent performing apps, and furthermore excellent E-Detailing tools.</div>
          </div>
            
          <Slider
            imageSrc={Mobile}
            title={null}
            subtitle={null}
            items={false}
            flipped={true}
            size={'100%'}
            />
        </div>
        <hr/>
        <div className='contact7'>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdOutlinePhoneInTalk/></span>
                <span className='text-indigo-600'> +0044 545 989 626</span>
            </div>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdMailOutline/></span>
                <span className='text-indigo-600 underline'> contact@arltechnology.co.uk</span>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default MobileDevelo
