import Diaporama1 from "../assets/Slider Images/Diaporama1.png";
import Diaporama2 from "../assets/Slider Images/Diaporama2.png";
import Diaporama3 from "../assets/Slider Images/Diaporama3.png";
export const SliderData = [
    {
        image:Diaporama1,
        words:'Input is Data, Output is Meaningful Interpretation, Outcome is better Decisions and Impact is Booming Success'
    },
    {
        image:Diaporama2,
        words:'The Basic Advantage Offered By A Solid Base Of Knowledge are Right Businnes Decisions, Reasoning and Explanation which use this base of knowledge to solve Complex Problems'
    },
    {
        image:Diaporama3,
        words:'we will help you Cross all the boundaries to creativity ; you will be able to come up with new creative ideas that will enable you to think widely , and approach matters in a new way'
    }

]

