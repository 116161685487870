import React from 'react'

import {
    FaFacebook,
    FaGithub,
    FaInstagram,
    FaTwitter,
    FaTwitch,
} from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Footer = () => {
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
      };
  return (
    <div className='w-full mt-4 bg-slate-900 text-gray-300 py-y px-2'>
        <div className='max-w-[1240px] mx-auto grid grid-cols-2 md:grid-cols-6 border-b-2 border-gray-600 py-8'>
            <div>  
                <h6 className='font-bold uppercase py-1 hover:font-bold'><Link to={"/"}>Home</Link></h6>
            </div>  
            <div>
                <h6 className='font-bold uppercase pt-2'>About us</h6>
                <ul>
                    <li><Link className='py-1 hover:font-bold' to={'/Vision'}>Vision</Link></li>
                    <li><Link className='py-1 hover:font-bold' to={'/Mission'}>Mission</Link></li>
                    <li><Link className='py-1 hover:font-bold' to={'/Core'}>Core Values</Link></li>
                </ul>
            </div>
            <div>
                <h6 className='font-bold uppercase pt-2'>Products</h6>
                <ul>
                <li><Link className='py-1 hover:font-bold' to={'/salesan'}>Sales Analysis System</Link></li>
                    <li><Link className='py-1 hover:font-bold' to={'/callrep'}>Call Reporting System</Link></li>
                    <li><Link className='py-1 hover:font-bold' to={'/Point'}>Point of Sales</Link></li>
                    <li><Link className='py-1 hover:font-bold' to={'/SalesForce'}>Sales Gorce Automation</Link></li>
                    <li><Link className='py-1 hover:font-bold' to={'/Mobile'}>Mobile Applications</Link></li>
                    <li><Link className='py-1 hover:font-bold' to={'/Agenda'}>Agenda</Link></li>
                </ul>
            </div>
            <div>
                <h6 className='font-bold uppercase pt-2'>Services</h6>
                <ul>
                <li><Link className='py-1 hover:font-bold' to={'/Web'}>Web Design</Link></li>
                    <li><Link className='py-1 hover:font-bold' to={'/Point'}>Point of Sales</Link></li>
                    <li><Link className='py-1 hover:font-bold' to={'/SalesForce'}>Sales Gorce Automation</Link></li>
                </ul>
            </div>
            <div>
                <h6 className='font-bold uppercase py-1 hover:font-bold'><Link to={"/Alliance"}>Alliances</Link></h6>
            </div>
            <div>
                <h6 className='font-bold uppercase pt-2'> Contact us</h6>
                <ul>
                    <Link className='py-1 hover:font-bold' to={'/Contact'}>Contact Us</Link>
                </ul>
            </div>
            {/*<div className='col-span-2 pt-8 md:pt-2'>
                <p className='font-bold uppercase'>Subscribe to our newsletter</p>
                <p className='py-4'>The latest news, articles, and resources, sent to your inbox weekly.</p>
                <form className='flex flex-col sm:flex-row'>
                    <input className='w-full p-2 mr-4 rounded-md mb-4' type="email" placeholder='Enter email..'/>
                    <button className='p-2 mb-4'>Subscribe</button>
                </form>
            </div>*/}
        </div>

        <div className='flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-gray-500'>
        <p className='py-4'>2022 Workflow, LLC. All rights reserved</p>
        <div className='flex justify-between sm:w-[300px] pt-4 text-2xl'>
            <button className='pb-5' onClick={() => openInNewTab('https://www.facebook.com/ARK.Corporate')}><FaFacebook /></button>
        </div>
        </div>
    </div>
  )
}

export default Footer