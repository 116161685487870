import React from 'react'
import Navbar from '../Navbar'
import Sliderv2 from '../Rotate'
import Commitment from '../../assets/Core Values/Commitment.png'
import Connection from '../../assets/Core Values/Connection.png'
import Creativity from '../../assets/Core Values/Creativity.png'
import Goals from '../../assets/Core Values/Goals.png'
import Innovation from '../../assets/Core Values/Innovation.png'
import Growth from '../../assets/Core Values/Growth.png'
import Passion from '../../assets/Core Values/Passion.png'
import Transparency from '../../assets/Core Values/Transparency.png'
import Footer from '../Footer'
import './CoreValues.css'
import { MdMailOutline, MdOutlinePhoneInTalk } from 'react-icons/md'
import Slider from '../Animate'
const CoreValues = () => {
  return (
    <div>
        <Navbar/>
        <div className='container'>
          <div className="write">
            <h1 className='font-bold underline'>Commitment :</h1>
            <div className='notflip'>
              <Slider
              imageSrc={Commitment}
              title={null}
              subtitle={null}
              items={false}
              flipped={true}
              size={'100%'}/>
            </div>
            <div>Since the best measure of commitment is outcomes, therefore we seek to provide time sensitive results for our customers. We are committed to details, accuracy, excellence and confidentiality for our customers. We invest time and resources to drive quality in all aspects of our business.  We strive to continually improve our processes for building, and delivering trusted products and services.','We are passionately committed to provide our customers with the highest levels of service. We are passionate indeed about facilitating an optimal way for our customers to effectively manage their IT environments, and solve any problem they encounter with practical and flexible solutions, to enable them to turn their business to be enjoyable, through building the knowledge that supports the right decision.','We at ARL-Technology are completely transparent and open in our communication with employees, customers, partners and suppliers. We don’t promise big. We just deliver what we promise.','in ARL Technology explore the world of innovation on web designs</div>
          </div>
          <div className='imm'>      
            <div className='flip'>
              <Slider
              imageSrc={Commitment}
              title={null}
              subtitle={null}
              items={false}
              flipped={false}
              size={'100%'}
              />
            </div>

          </div>
          
        </div>
        <div className='containers'>
          <div className="write">
            <h1 className='font-bold underline'>Passion :</h1>
            <div className='notflip'>
                <Slider
                imageSrc={Passion}
                title={null}
                subtitle={null}
                items={false}
                flipped={true}
                size={'100%'}
                />
            </div>
            <div>We are passionately committed to provide our customers with the highest levels of service. We are passionate indeed about facilitating an optimal way for our customers to effectively manage their IT environments, and solve any problem they encounter with practical and flexible solutions, to enable them to turn their business to be enjoyable, through building the knowledge that supports the right decision.</div>
          </div>
          <div className='imm'>
              <div className='flip'>
              <Slider
                imageSrc={Passion}
                title={null}
                subtitle={null}
                items={false}
                flipped={true}
                size={'100%'}
                />
              </div>
            </div>  
        </div>
        <div className='containers'>
          <div className="write">
            <h1 className='font-bold underline'>Innovation :</h1>
            <div className='notflip'>
                <Slider
                  imageSrc={Innovation}
                  title={null}
                  subtitle={null}
                  items={false}
                  flipped={true}
                  size={'80%'}
                  />
            </div>
            <div>in ARL Technology explore the world of innovation on web designs</div>
          </div>
          <div className='imms'>
            <div className='flip'>
              <Slider
                imageSrc={Innovation}
                title={null}
                subtitle={null}
                items={false}
                flipped={true}
                size={'80%'}
                />
            </div>
          </div>
          
        </div>
        <hr/>
        <div className='resp'>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdOutlinePhoneInTalk/></span>
                <span className='text-indigo-600'> +0044 545 989 626</span>
            </div>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdMailOutline/></span>
                <span className='text-indigo-600 underline'> contact@arltechnology.co.uk</span>
            </div>
        </div>
        <Footer/>
    </div>
    
    /*<div>
        <Navbar/>
        <div className='sld'>
            <Sliderv2 
                imageSrc={[Commitment,Passion,Transparency,Innovation]}
                title={'Core Values :'}
                subtitle={['Since the best measure of commitment is outcomes, therefore we seek to provide time sensitive results for our customers. We are committed to details, accuracy, excellence and confidentiality for our customers. We invest time and resources to drive quality in all aspects of our business.  We strive to continually improve our processes for building, and delivering trusted products and services.','We are passionately committed to provide our customers with the highest levels of service. We are passionate indeed about facilitating an optimal way for our customers to effectively manage their IT environments, and solve any problem they encounter with practical and flexible solutions, to enable them to turn their business to be enjoyable, through building the knowledge that supports the right decision.','We at ARL-Technology are completely transparent and open in our communication with employees, customers, partners and suppliers. We don’t promise big. We just deliver what we promise.','in ARL Technology explore the world of innovation on web designs']}
                items={false}
                flipped={false}
                size={"30%"}
            />
        </div>
        <div className='cont'>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdOutlinePhoneInTalk/></span>
                <span className='text-indigo-600'> +20222030198</span>
            </div>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdMailOutline/></span>
                <span className='text-indigo-600 underline'> info@ARL-technology.com</span>
            </div>
        </div>
        
    </div>
  */
  )
}

export default CoreValues
