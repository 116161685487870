import React from 'react'
import { MdMailOutline, MdOutlinePhoneInTalk } from 'react-icons/md'
import Slider from '../Animate'
import Navbar from '../Navbar'
import Web from '../../assets/Slider Images/WebDesign.png'
import Footer from '../Footer'
import './Web Design.css'
import { Link } from 'react-scroll'

const WebDesign = () => {
  return (
    <div>
        <Navbar/>
        <div className='container9'>
          <div className="content9">
            <h1 className='font-bold underline'>Web Design</h1>
            <div><span className='font-bold'>ARL-Technology</span> helps you promote your ideas and create an online presence. We can also help you to communicate easily with your customers and staff.</div>
            <div><span className='font-bold'>ARL-Technology</span> helps you build the right image and give your company a distinguished look that makes difference in the targeted mARLet and lunch your business quickly.</div>
            <div><span className='font-bold'>ARL-Technology</span> offers custom made and unique web design services to open new doors for our partners and expand their businesses. 
          </div>
          </div>
            
          <Slider
            imageSrc={Web}
            title={null}
            subtitle={null}
            items={false}
            flipped={true}
            size={'80%'}
            />
        </div>
        <hr/>
        <div className='contact9'>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdOutlinePhoneInTalk/></span>
                <span className='text-indigo-600'> +0044 545 989 626</span>
            </div>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdMailOutline/></span>
                <span className='text-indigo-600 underline'> contact@arltechnology.co.uk</span>
            </div>
        </div>
        <div>
        </div>
        <Footer/>
    </div>
  )
}

export default WebDesign
