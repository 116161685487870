import React, { useState,useEffect }  from 'react'
import { SliderData } from './SliderData'
import { FFaChevronLeft,FaChevronRight, FaChevronLeft } from "react-icons/fa";
import styled from "styled-components";
const IndicatorWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  position: absolute;
  bottom: 15px;
  right: 15px;
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: white;
  opacity: ${(props) => (props.isActive ? 1 : 0.5)};
  margin: 5px;
  transition: 750ms all ease-in-out;
`;
const Indicator = ({ currentSlide, amountSlides, nextSlide }) => {
    return (
      <IndicatorWrapper>
        {Array(amountSlides)
          .fill(1)
          .map((_, i) => (
            <Dot
              key={i}
              isActive={currentSlide === i}
              onClick={() => nextSlide(i)}
            />
          ))}
      </IndicatorWrapper>
    );
  };
const ImageSlider = ({slides,
    autoPlay = true,
    autoPlayTime = 5000,
    children,
    ...props}) => {
    const [current, SetCurrent] = useState(0)
    const length = slides.length
    const nextSlide = () => {
        SetCurrent(current === length - 1 ? 0 : current + 1)
    }

    const prevSlide = () => {
        SetCurrent(current === 0 ? length-1 : current - 1)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
          nextSlide();
        }, autoPlayTime);
    
        return () => clearTimeout(timer);
      }, [current]);

    if(!Array.isArray(slides) || slides.length <=0) {
        return null;
    }
  return (
    <section className='sldr'>
        <div className='left'><FaChevronLeft className='absolute top-1/2 left-52  text-5xl bg-transparent z-9 cursor-pointer select-none' onClick={prevSlide}/></div>
        <div className='right'><FaChevronRight className='absolute top-1/2 right-52 text-5xl bg-transparent z-9 cursor-pointer select-none' onClick={nextSlide}/></div>
        {SliderData.map((slide,index) => {
            return(
                <div className={index === current ? 'slide active' : 'slide'} key={index}>
                    {index === current && (<div className='section-banner'><div className='w-1/4 pt-28'>{slide.words}</div><img src={slide.image} alt='us' className='max-w-3xl h-72 rounded-xl'></img></div>)}
                    
                </div>
                
            )
        })}
    </section>
  )
}

export default ImageSlider
