import React from "react";
import { MdRestaurant } from "react-icons/md";
import { useInView } from "react-intersection-observer";
import '../Animate.css'

const Slider = ({ imageSrc, title, subtitle=[], flipped,items,size}) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.4,
  });

  const renderContent = () => {
    if(items){
      if (!flipped) {
        return (
          <>
            <img src={imageSrc} style={{width:size}}/>
            <div className="slider__content">
              <h1 className="slider__title">{title}</h1>
              {subtitle.map((subt)=>{
                    <div>{subt}</div>
              })};
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="slider__content">
              <h1 className="slider__title">{title}</h1>
              {subtitle.map((subt)=>{
                return(
                  <div>{subt}</div>
                )
              })}
            </div>
            <img src={imageSrc} alt="Travel" style={{width:size}}/>
          </>
        );
      }
  } else {
    if (!flipped) {
      return (
        <>
          <img src={imageSrc} alt="Travel" style={{width:size}}/>
          <div className="slider__content">
            <h1 className="slider__title">{title}</h1>
            <p>{subtitle}</p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <img src={imageSrc} alt="Travel" className="drop-shadow-2xl" style={{width:size}}/>
        </>
      );
    }
    }
    }
    

  return (
    <div className={inView ? "slider slider--zoom" : "slider"} ref={ref}>
      {renderContent()}
    </div>
  );
};

export default Slider;
