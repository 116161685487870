import React from 'react'
import "../../App.css"
import {MdOutlinePhoneInTalk,MdMailOutline} from "react-icons/md"
import {
    CloudUploadIcon,
    DatabaseIcon,
    PaperAirplaneIcon,
    ServerIcon,
} from '@heroicons/react/solid'
import bgImg from '../../assets/cyber-bg.png'
import ImageSlider from '../ImageSlider'
import { SliderData } from '../SliderData'
import image1 from "../../assets/Slider Images/Image1.png"
import Slider from '../Animate'
import Footer from '../Footer'
import './Home.css'
const Hero = () => {
  return (
    <div className='w-screen h-screen justify-center bg-zinc-100'>
        <div name='home' className='w-full h-screen bg-zinc-200 flex flex-col justify-between'>
        <div className='grid md:grid-cols-2 max-w-[1240px] m-auto'>
            <div className='flex flex-col justify-center md:items-start w-full px-2 py-8'>
                <p className='text-2xl'>Unique Sequencing & Production</p>
                <h1 className='py-3 text-5xl md:text-7xl font-bold'>ARL Technology</h1>
                <p className='text-2xl'>Enjoy Your Business</p>
            </div>
            <div>
            <Slider
            imageSrc={bgImg}
            title={null}
            subtitle={null}
            size={'100%'}
            />
            </div>
            <div className='absolute flex flex-col pt-4 py-8 md:min-w-[760px] bottom-[5%]
            mx-1 md:left-1/2 transform md:-translate-x-1/2 bg-zinc-200
            border border-slate-300 rounded-xl text-center shadow-xl'>
                <p>Web Services</p>
                <div className='flex justify-between flex-wrap px-4'>
                    <p className='flex px-4 py-2 text-slate-500'><CloudUploadIcon className='h-6 text-indigo-600' /> Web Security</p>
                    <p className='flex px-4 py-2 text-slate-500'><DatabaseIcon className='h-6 text-indigo-600' /> Dashboard Design</p>
                    <p className='flex px-4 py-2 text-slate-500'><ServerIcon className='h-6 text-indigo-600' /> Cloud Data</p>
                    <p className='flex px-4 py-2 text-slate-500'><PaperAirplaneIcon className='h-6 text-indigo-600' /> API</p>
                </div>
            </div>
        </div>
    </div>
        <ImageSlider slides={SliderData}/>
    <div className='texts md:grid-cols-2 max-w-[1240px] m-auto'>
        <div className="p-8 font-['Proxima Nova']">
            <div className='p-3'>
                <span className='font-bold underline'>ARL-Technology</span> is founded on the belief that business intelligence (BI) should be about enabling business users to enjoy doing business; we help business users to build a solid base of knowledge that supports making the right decisions.
            </div>
            <div className='p-3'>
                <span className='font-bold underline'>ARL-Technology</span> is focused on simplifying decision making for business users; we plan, develop and support software applications used to collect, store, analyse and report data from various operating departments of a company. We use new approaches to access, manage, and interact with data. Our business solutions are recognized as ground-breaking solutions.
            </div>
            <div className='p-3'>
                <span className='font-bold underline'>ARL-Technology</span> delivers software that helps business users discover new insights, grow revenues, reduce costs and improve corporate performance by enhancing the decision making and unlocking actionable insights contained within their data. We provide systems that play a key role in the strategic planning process of a company, and provide smart analysis regarding customers, mARLet trends, forecasting, and performance management.
            </div>
        </div>
        <div>
            <Slider
                imageSrc={image1}
                title={null}
                subtitle={null}
                size={'100%'}
                />
            </div>
        </div>
 
        <hr/>
        <div className='contact12'>
            <div className='text-xl font-semibold px-10 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdOutlinePhoneInTalk/></span>
                <span className='text-indigo-600'> +0044 545 989 626</span>
            </div>
            <div className='text-xl font-semibold px-10 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdMailOutline/></span>
                <span className='text-indigo-600 underline'> contact@arltechnology.co.uk</span>
            </div>
        </div>
        <Footer/>
    </div>
    
  )
}

export default Hero