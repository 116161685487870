import React from 'react'
import Navbar from '../Navbar'
import vision1 from "../../assets/Slider Images/Vision.png"
import { MdMailOutline, MdOutlinePhoneInTalk } from 'react-icons/md'
import Slider from '../Animate'
import Footer from '../Footer'
import './Vision.css'
const Vision = () => {
  return (
    <div>
        <Navbar/>
        <div className='container1 md:grid-cols-2'>
            <div className="content">
                <h1 className='pt-7 font-bold text-lg underline'>Vison :</h1>
                <div className='p-3 text-lg pt-20'>
                    <span className='font-bold'>Our Vision is to turn business to be enjoyable ,</span> through building the knowledge that supports the right
                </div>
            </div>
                <div className='pt-10'>
                    <Slider
                    imageSrc={vision1}
                    title={null}
                    subtitle={null}
                    size={'100%'}
                    />
                </div>
            </div>
        <hr/>
        <div className='contact'>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdOutlinePhoneInTalk/></span>
                <span className='text-indigo-600'> +0044 545 989 626</span>
            </div>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdMailOutline/></span>
                <span className='text-indigo-600 underline'> contact@arltechnology.co.uk</span>
            </div>
        </div>
        <Footer/>
    </div>
    
  )
}

export default Vision
