import React from 'react'
import Navbar from '../Navbar'
import Mission from "../../assets/Slider Images/Mission.png"
import { MdMailOutline, MdOutlinePhoneInTalk } from 'react-icons/md'
import Slider from '../Animate'
import Footer from '../Footer'
import '../../Animate.css'
import './Mission.css'

const Mission1 = () => {
  return (
    <div>
        <Navbar/>
        <div className='container'>
          <div className="write">
            <h1 className='font-bold underline'>Mission :</h1>
            <div>We at ARL Technology develop and support customized Business Intelligence (BI) Solutions; simple software that best fits our customer.</div>
            <div>Being talented, skilled and certified experts in Management Information Systems (MIS), we offer Decision Support Systems (DSS) which help you to get the best out of your human intelligence.</div>
            <div>We thrive to invade more markets and expand our scope to exceed the pharmaceutical market, where we already have built a great experience with our customers who are considered partners in our success.</div>
          </div>
            
          <Slider
            imageSrc={Mission}
            title={null}
            subtitle={null}
            items={false}
            flipped={true}
            size={'100%'}
            />
        </div>
        <hr/>
        <div className='resp'>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdOutlinePhoneInTalk/></span>
                <span className='text-indigo-600'> +0044 545 989 626</span>
            </div>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdMailOutline/></span>
                <span className='text-indigo-600 underline'> contact@arltechnology.co.uk</span>
            </div>
        </div>
        <Footer/>
    </div>
    
  )
}

export default Mission1
