import React from 'react';
import {Link} from "react-router-dom";
import {FiChevronDown, FiChevronUp } from 'react-icons/fi';
function Navlinks() {
    const links=[
        {name: "Home" , submenu:false , link:"/"},
        {name:"About us", submenu: true ,sublinks:[{
            sublink : [
                {name:"Vision", link:"/Vision"},
                {name:"Mission", link:"/Mission"},
                {name:"Core Values", link:"/Core"}
            ]
        }]},
        {name: "Products", submenu: true ,sublinks:[{
            sublink:[
                {name:"Sales Analysis System", link:"/salesan"},
                {name:"Call Reporting System", link:"/callrep"},
                {name:"Point of Sales", link:"/Point"},
                {name:"Sales Force Automation", link:"/SalesForce"},
                {name:"Mobile Applications",link:"/Mobile"},
                {name:"Agenda",link:"/Agenda"},
            ]
        }]},
        {name: "Services",submenu:true,sublinks:[{
            sublink:[
                {name:"Web Design", link:"/web"},
                {name:"Mis Consultancy", link:"/Consultancy"},
                {name:"Technical Support", link:"/technicalsupp"},
            ]
        }]},
        {name: "Alliances" , submenu:false , link:"/Alliance"},
        {name: "Contacts", submenu:true,sublinks:[{
            sublink:[
                {name:"Contact Us", link:"/Contact"},
            ]
        }]}
    ];
    return <>
    {
        links.map(link=>(
            <div>
                <div className='px-3 text-left md:cursor-pointer group'>
                    
                    <h1 className='p-4 px-4 inline-flex hover:bg-gradient-to-r from-slate-400 to-slate-300 rounded-md text-gray-800 hover:scale-110 duration-300 py-1 font-semibold'>
                        {link.submenu===true?<span className='p-1 flex'><span>{link.name}</span><span className='pt-1'><FiChevronDown/></span></span>:<Link to={link.link}>{link.name}</Link>}
                    </h1>
                    {link.submenu===true && (
                        <div>
                            <div className='absolute top-15 hidden group-hover:block hover:block'>
                                <div className='py-3'>
                                    <div className='w-4 h-4 left-3 absolute mt-1 bg-white rotate-45'>
                                    </div>
                                </div>
                                <div className='bg-slate-600 rounded-md'>
                                    {
                                        link.sublinks.map((mysublinks) => (
                                            <div>
                                                {mysublinks.sublink.map((slink)=> (
                                                    <li className="text-sm text-slate-100 p-3.5 my-2.5 hover:bg-gradient-to-r from-cyan-500 to-cyan-200">
                                                        <Link to={link.submenu===true?slink.link:null}>{slink.name}</Link>
                                                    </li>
                                                ))}
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        )
                    }
                       
                </div>
            </div>
        ))
    }
    </>
}
export default Navlinks