import React, { useState } from 'react';
import './Form.css';
import FormSignup from './FormSignup';
import FormSuccess from './FormSuccess';
import Agenda from '../../../assets/Slider Images/Agenda.png'
import { FiCheck } from 'react-icons/fi'
import { Link } from 'react-router-dom';
import { MdMailOutline, MdOutlinePhoneInTalk } from 'react-icons/md';
import Footer from '../../Footer';
const Form = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  function submitForm() {
    setIsSubmitted(true);
  }
  return (
    <div>
      <div className='form-container'>
        <div className='form-content-left'>
          <img className='form-img' src={Agenda} alt="" />
          <div className='elems'>
            <div>1- Download your free copy  (HOW TO UPDATE YOUR CLINIC).</div>
            <div className='font-semibold text-center'>Upon your request</div>
            <div>2- Schedule an appointment in your clinic and let us Digitalize your clinic.</div>
            <div>3-Enjoy our  FREE First Month in managing your social media profiles & running professional ads.</div>
          </div>
        </div>
          {!isSubmitted ? (
          <FormSignup submitForm={submitForm} />
          ) : (
            <FormSuccess />
          )}


      </div>
      
      <div className='left-1/2'>
          <div className='words'><div><div className='py-1 px-2'><FiCheck/></div>Promote an elegant and prestigious look for your clinic.</div>

            <div><div className='py-1 px-2'><FiCheck/></div>Contact your patients with elegance.</div>

            <div><div className='py-1 px-2'><FiCheck/></div>Use the latest technology at your clinic.</div>

            <div><div className='py-1 px-2'><FiCheck/></div>Your patients can reserve & pay online 24 hours / 7 days a week through customized app carrying your name.</div>

            <div><div className='py-1 px-2'><FiCheck/></div>Ensure the highest level of satisfaction for your patient.</div>

            <div><div className='py-1 px-2'><FiCheck/></div>Keep them updated by their roles through your customized app (Agenda).</div>

            <div><div className='py-1 px-2'><FiCheck/></div>From your desk, follow all the clinic processes through your app.</div>

            <div><div className='py-1 px-2'><FiCheck/></div>Your patients can easily download your customized app by a simple QR code scan at your reception.</div>
            <p><div>Don’t hesitate…</div>
            <div>Differentiate your clinic…</div>
            <div>Contact us now to arrange a meeting at your clinic.</div></p>
            <div className='justify-center'><Link to={"/Contact"} className="text-4xl bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">Contact us</Link></div>
          </div>
        </div>
        <hr/>
        <div className='content11'>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdOutlinePhoneInTalk/></span>
                <span className='text-indigo-600'> +0044 545 989 626</span>
            </div>
            <div className='text-xl font-semibold px-7 flex items-center pr-4'>
                <span className='animate-bounce text-3xl pr-2 text-blue-500'><MdMailOutline/></span>
                <span className='text-indigo-600 underline'> contact@arltechnology.co.uk</span>
            </div>
        </div>
        <Footer/>
    </div>
  );
};

export default Form;
